import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'EGGP',
    stakingTokenName: QuoteToken.EGGP,
    stakingTokenAddress: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
    contractAddress: {
      97: '',
      56: '0x5C5c8a3176b98F3409a7E97CCeE5DfF6096Ce44B',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://eggplant.fi/',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
]

export default pools
