import { EPNFTConfig } from './types'

const epnfts: EPNFTConfig[] = [
  {
    storeAddress: {
      97: '0xb52B36c5392013e1667263031d698e5B5Dd34F9f',
      56: '0x68D7Fd1Dfb792199A0021567A82e2e4EbdCb3B7F',
    },
    eprtTokenAddress : {
      97: '0xB717D067539fD716B706180182FB20844B3eECE8',
      56: '0xAa985546349eacAAF5A293e2b478F1C171f29F41',
    },
    eggpTokenAddress : {
      97: '0xA780F871b0F93C416094B0575C1b862F48C31c8a',
      56: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
    },
    busdTokenAddress : {
      97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    cakeTokenAddress : {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    nftAddress: {
      97: '',
      56: '',
    },
    epCommonTokenAddress : {
      97: '',
      56: '0x53EeeB12301957E0581B8E3831677329bb291a36'
    },
    epBetaTokenAddress : {
      97: '',
      56: '0x0E7a21E37e4AEE4a345752CFbc04cF28F58f68ba'
    },
    epSinglePromoClaimAddress : {
      97: '0x37474082AE5c8F6DD7d49e91c5BB6F757f4c6f3E',
      56: ''
    }
  },

]

export default epnfts
