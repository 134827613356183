import { MenuEntry } from '@eggplant-finance/eggplant-uikit-theme'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://swap.eggplant.fi/#/swap',
          external: true
      },
      {
        label: 'Liquidity',
        href: 'https://swap.eggplant.fi/#/pool',
          external: true
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //     label: 'NFTs',
  //     icon: 'NftIcon',
  //     items: [
  //         // {
  //         //     label: 'Buy',
  //         //     href: '/buy-nft'
  //         // },
  //         // {
  //         //     label: 'Stake',
  //         //     href: '/stake-nft',
  //         // },
  //     ],
  // },
  {
      label: 'NFTs',
      icon: 'NftIcon',
      href: '/nft',
  },
  {
      label: 'Games',
      icon: 'TicketIcon',
      href: '/games',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
          label: 'Dex.Guru Chart',
          href: 'https://dex.guru/token/0x21adb1c644663069e83059ac3f9d9ca1133d29e4-bsc',
          external: true
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/eggplant-finance',
        external: true
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/eggplant-finance/',
        external: true
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/eggplant-finance/',
        external: true
      },
      {
        label: 'Medium',
        href: 'https://eggplantfinance.medium.com/',
        external: true
      },
      {
        label: 'Certik Audit',
        href: 'https://www.certik.org/projects/eggplant',
        external: true
      },
    ],
  },
]

export default config
