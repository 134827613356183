import styled from 'styled-components'
import Container from './Container'

const Page = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-bottom: 180px;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  flex-direction: column;
  align-content: center; 

  @media only screen and (min-width: 1000px) {
    min-width: 992px;
  }
  
  // background-color: yellow;
  

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-bottom: 32px;
  }
`

export const MainWrapper = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-bottom: 16px;
`

export default Page
