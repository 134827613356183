import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@eggplant-finance/eggplant-uikit-theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Baloo 2', sans-serif;
  }
  body {
  // main site bg background color

    // create a radial main background
    background: ${({ theme }) => (
      theme.isDark 
        ? 'radial-gradient(ellipse at 50% 70%, rgba(107,66,158,1)0%, rgba(34,19,45,1)100%)' 
        // : 'radial-gradient(ellipse at 50% 70%, #ffffff, #ffffff)'
        : 'linear-gradient(180deg, rgba(255,255,255,1)33%,rgba(184,165,193,1)100%)'
      )
    };
    background-repeat: no-repeat;
    background-attachment: fixed;
  // background: radial-gradient(ellipse at 50% 70%, #7b4bb7, #22132d);
  // background-repeat: no-repeat;
  
  // background-image: ${({ theme }) => (theme.isDark ? '' : 'url(/images/nfts/bg-nfts-light.png)')};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  // each page background images
  .home-bg {
    background-image: ${({ theme }) => (theme.isDark ? '' : '')};
  }
  .pools-bg {
    background-image: ${({ theme }) => (theme.isDark ? '' : '')};
  }
  .farms-bg {
    background-image: ${({ theme }) => (theme.isDark ? '' : '')};
  }
  .nfts-bg {
    background-image: ${({ theme }) => (theme.isDark ? '' : 'url(../../images/nfts/bg-nfts-light.png)')}
    // background-image: url('../../images/nfts/bg-nfts-light.png') !important;
  }
  .card {
  width: 220px;
  height: 307px;
  background-color: #211799;
  color: #ffffff;
  background-image: url(/images/rocket-card.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5% / 3.5%;
  box-shadow: -3px -3px 3px 0 rgba(#26e6f7, 0.3), 3px 3px 3px 0 rgba(#f759e4, 0.3), 0 0 6px 2px rgba(#ffe759, 0.3),
    0 35px 25px -15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin: 20px 10px;
  animation: holoCard 15s ease infinite;
}

.card > span {
  position: relative;
  top: 45%;
}

.card:before,
.card:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    rgb(0, 231, 255) 30%,
    rgb(255, 0, 231) 70%,
    transparent 100%
  );
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 300% 300%;
  mix-blend-mode: color-dodge;
  opacity: 0.2;
  z-index: 1;
  animation: holoGradient 15s ease infinite;
}
.card:after {
  background-image: url("/images/sparkles.gif");
  background-position: center;
  background-size: 180%;
  mix-blend-mode: color-dodge;
  opacity: 1;
  z-index: 2;
  animation: holoSparkle 15s ease infinite;
}

.card.active:before {
  opacity: 1;
  animation: none;
  transition: none;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    transparent 25%,
    rgba(0, 231, 255,0.7) 45%,
    rgba(255, 0, 231,0.7) 55%,
    transparent 70%,
    transparent 100%
  );
}

.card.active:before,
.card.active:after {
  animation: none;  
}

.card:nth-of-type(1),
.card:nth-of-type(2) {
  width: 160px;
  height: 226px;
  box-shadow: 0 0 1px 1px rgba(white,0.4), 0 25px 15px -10px rgba(0, 0, 0, 0.5);
}

.card:nth-of-type(1),
.card:nth-of-type(2) {
  &:before, &:after {
    animation: none;
    opacity: 1;
  } 
}
.card:nth-of-type(1) {
  &:before, &:after { display: none; }
}
.card:nth-of-type(2) {
  background: none;
  &:before { display: none; }
  text-align: center;
}

.operator {
  display: inline-block;
  vertical-align: middle;
  font-size: 45px;
  color: #A28BD4;
}
@keyframes holoSparkle {
  0%, 5% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes holoCard {
  0%, 100% {
    transform: none;
  }
  25% {
    transform: rotateZ(-3deg) rotateX(-7deg) rotateY(6deg);
  }
  66% {
    transform: rotateZ(3deg) rotateX(7deg) rotateY(-6deg);
  }
}

#nft-main-section {
  position: relative;
  perspective: 1000px;
}
`

export default GlobalStyle
