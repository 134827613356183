import addresses from 'config/constants/contracts'
import epnAddresses from 'config/constants/epnfts'

export const getAddress = (address): string => {
  const mainNetChainId = 56
  const chainId = 56 // process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getNftStoreAddress = () => {
  return epnAddresses[0].storeAddress[chainId]
}
export const getEprtTokenAddress = () => {
  return epnAddresses[0].eprtTokenAddress[chainId]
}
export const getEggpTokenAddress = () => {
  return epnAddresses[0].eggpTokenAddress[chainId]
}
export const getBusdTokenAddress = () => {
  return epnAddresses[0].busdTokenAddress[chainId]
}
export const getCakeTokenAddress = () => {
  return epnAddresses[0].cakeTokenAddress[chainId]
}
export const getEpCommonTokenAddress = () => {
  return epnAddresses[0].epCommonTokenAddress[chainId]
}
export const getEpBetaTokenAddress = () => {
  return epnAddresses[0].epBetaTokenAddress[chainId]
}
export const getEpSinglePromoClaimAddress = () => {
  return epnAddresses[0].epSinglePromoClaimAddress[chainId]
}
