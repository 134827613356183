import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@eggplant-finance/eggplant-uikit-theme'
import BigNumber from 'bignumber.js'
import ReactNotification from 'react-notifications-component'
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import {MainWrapper} from './components/layout/Page'
// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
// const Lottery = lazy(() => import('./views/Lottery'))
const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const Nft = lazy(() => import('./views/Nft'))
const NftBuying = lazy(() => import('./views/Nft-buying'))
const NftStaking = lazy(() => import('./views/Nft-staking'))
const NftMine = lazy(() => import('./views/Nft-mine'))
const NftClaim = lazy(() => import('./views/Nft-claim'))
const Games = lazy(() => import('./views/Games'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <MainWrapper>
      <ReactNotification />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Farms tokenMode />
            </Route>
            <Route path="/nft">
              <Nft />
            </Route>
            <Route path="/buy-nft">
              <NftBuying />
            </Route>
            <Route path="/stake-nft">
              <NftStaking />
            </Route>
            <Route path="/my-nfts">
              <NftMine />
            </Route>
            <Route path="/claim-prize">
              <NftClaim />
            </Route>
            <Route path="/games">
              <Games />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      </MainWrapper>
      {/* <NftGlobalNotification /> */}
    </Router>
  )
}

export default React.memo(App)
