import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@eggplant-finance/eggplant-uikit-theme'
import Page from './layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1000px) {
    min-width: 992px;
  }
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  )
}

export default PageLoader
