export default {
  cake: {
    56: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4', // this is EGGP
    97: '0xA780F871b0F93C416094B0575C1b862F48C31c8a',
  },
    realcake: {
        56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', // this is actual cake
        97: '',
    },
  masterChef: {
    56: '0x5C5c8a3176b98F3409a7E97CCeE5DfF6096Ce44B',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0x641993f585a70a004d204b6417eeecd3a86d36ed',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
  },
}
